<template>
  <div
    class="tendency-wrap"
    v-loading.fullscreen='loading'
    element-loading-text="加载中"
  >
    <div class="tendency-breadcrumb">
      <span
        class="pointer"
        @click="toBrandTendency"
      >商业洞察</span> > <span
        class="pointer"
        @click="toList"
      >{{title}}</span> > <span class="active">正文</span>
    </div>
    <div class="tendency-content">
      <div class="tendency-left">
        <div class="article-detail">
          <h2 class="title">{{detail.title}}</h2>
          <div class="article-time-report">
            {{detail.source}} {{detail.newsTime}}
          </div>
          <div
            class="article-tip"
            v-if="detail.hexintishi"
          >
            <div class="article-tip-text">
              核心提示
            </div>
            <p><i class="icon-small-tip"></i><span>{{detail.hexintishi}}</span></p>
          </div>
          <div
            class="article-detail-content"
            v-html='detail.content'
          >
          </div>
          <div class="article-tip">
            <p v-if="detail.source && detail.source.indexOf('赢商网')>=0"><span class="article-copyright">赢商网原创新闻，转载或内容合作请点击转载说明，违规转载法律必究</span></p>
            <p v-else><span class="article-copyright">本文转载来自：{{detail.source}}，不代表赢商网观点，如需转载请联系原作者。如涉及版权问题请联系赢商网，电话：020-37128209；邮箱：news@winshang.com</span></p>
          </div>
        </div>
        <div class="tendency-article">
          <h2 class="tendency-title"><i></i>你可能感兴趣</h2>
          <news-item
            v-for="item in tendencyList"
            :key="item.newsId"
            :detail="item"
            title="商业洞察列表"
          ></news-item>
        </div>
      </div>
      <div class="tendency-right">
        <hot-focus class="mb-20"></hot-focus>
        <brand-top class="mb-20"></brand-top>
        <brand-tab></brand-tab>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import brandTop from './brandTop.vue'
import newsItem from './newsItem.vue'
import brandTab from './brandTab'
import hotFocus from './hotFocus'
export default {
  data () {
    return {
      search: '',
      title: '',
      newsId: '',
      detail: {},
      tendencyList: [],
      loading: false
    }
  },
  components: {
    brandTop,
    newsItem,
    brandTab,
    hotFocus
  },
  created () {
    this.loading = true
    this.newsId = this.$route.query.newsId
    this.getDetail()
    this.getTendencyList()
  },
  methods: {
    getDetail () {
      this.axios.post(api.getNewsDetail, {
        newId: this.newsId
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0 && res.data.data) {
            this.detail = res.data.data
            if (this.detail.category.indexOf('6303') >= 0) {
              this.title = '品牌盘点列表'
            } else {
              this.title = '商业洞察列表'
            }
          }
        })
    },
    getTendencyList () {
      this.axios.post(api.getNewsBrandNewsSearch, {
        pageNum: 1,
        pageSize: 5,
        category: '推荐',
        keyWord: ''
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.tendencyList = res.data.data.newsListVos.slice(0, 5)
          }
        })
    },
    toDetail () {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent(this.title)
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    // 更多资讯
    toList () {
      const page = this.$router.resolve({
        path: '/brandTendencyList',
        query: {
          type: this.title === '商业洞察列表' ? 'Tendency' : 'Inventory'
        }
      })
      window.open('/' + page.href, '_blank')
    },
    toBrandTendency () {
      this.$router.push({
        path: '/brandTendency'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.mb-20
  margin-bottom 20px
.tendency-wrap
  margin 0 auto
  width 1280px
  color #ffffff
.tendency-content
  display flex
  .tendency-left
    width 808px
    margin-right 23px
  .tendency-right
    width 450px
  img
    width 100%
.tendency-search
  margin-bottom 25px
  display flex
  input
    line-height 40px
    height 40px
    background rgba(39, 41, 48, 1)
    border 1px solid transparent
    outline none
    color #ffffff
    font-size 14px
    padding 0 21px
    flex 1
  span
    color #ffffff
    font-size 14px
    width 113px
    background rgba(62, 62, 69, 1)
    display inline-block
    line-height 40px
    height 40px
    text-align center
    i
      font-size 17px
      margin-right 6px
      margin-left -18px
      vertical-align middle
      color #ffffff
      &:before
        content '\E741'
        color #ffffff
.tendency-article
  background-color #272930
  border-radius 2px
  padding-top 25px
.tendency-title
  position relative
  font-size 18px
  color #fff
  font-weight 500
  margin-left 20px
  padding-left 10px
  i
    position absolute
    left 0
    top 3px
    width 2px
    height 18px
    background #ffa134
.tendency-breadcrumb
  color #9B9B9B
  font-size 14px
  margin-bottom 15px
  margin-top 15px
  .active
    color #ffffff
.article-detail
  background-color #272930
  padding 30px 25px
  .title
    font-size 24px
    line-height 24px
  .article-time-report
    margin-top 20px
    font-size 12px
    color #9B9B9B
.article-tip
  position relative
  border-bottom 1px solid #424242
  border-top 1px solid #424242
  margin-top 40px
  padding 20px 10px 15px 5px
  .article-tip-text
    background-color #272930
    color #999
    position absolute
    left 50%
    top -12px
    font-size 14px
    transform translateX(-50%)
    width 90px
    text-align center
  p
    color #ffffff
    font-size 14px
    line-height 20px
    display flex
    i
      margin-right 6px
      &:before
        content '\E63D'
        color #ffa134
        font-size 25px
        vertical-align super
    span
      display inline-block
  .article-copyright
    color #999
.article-detail-content
  margin 32px 0
  color #ffffff
  font-size 16px
  line-height 28px
  p
    margin-bottom 15px
</style>
<style lang="stylus">
.article-detail-content
  &>*
    color #ffffff !important
  p
    margin-bottom 20px
  img
    max-width 100%
</style>
