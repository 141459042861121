<template>
  <div class="hot-focus">
    <div class="brand-title">
      <h2 class="tendency-title"><i></i>热点关注</h2>
    </div>
    <ul>
      <li
        v-for="item in hotList"
        :key="item.newsId"
        @click="toNewsDetail(item)">{{item.title}}</li>
    </ul>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      hotList: []
    }
  },
  components: {
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.post(api.getNewsHitFocus)
        .then((res) => {
          if (res.data.code === 0) {
            this.hotList = res.data.data
          }
        })
    },
    toNewsDetail (detail) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent('热点关注'),
          newsId: detail.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.hot-focus
  background-color #272930
  border-radius 4px
  ul
    padding 20px
    li
      color #ffffff
      font-size 16px
      line-height 34px
      font-weight 400
      list-style inside
      margin-left 4px
      cursor pointer
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover
        color #FFA134
.brand-title
  padding 20px
  border-bottom 1px solid #424242
.tendency-title
  position relative
  font-size 18px
  color #fff
  font-weight 500
  padding-left 10px
  i
    position absolute
    left 0
    top 3px
    width 2px
    height 18px
    background #ffa134
</style>
